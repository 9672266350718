@import '@we-make-websites/styles-lib/src/config/configuration';
.signupForm {
  $parent: &;
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: grid;
    margin-block-end: var(--spacing-9xl);
    margin-block-start: var(--spacing-3xl);

    /* Doubling specificity */
    &#{&} {
      row-gap: var(--spacing-3xl);
    }
  }

  &__radioButtons {
    display: flex;
    gap: var(--spacing-m);
    margin-block-end: var(--spacing-l);
    margin-block-start: var(--spacing-s);
  }

  &__el {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
  }

  &__title,
  &__subtitle {
    margin-block-end: var(--spacing-m);
  }

  &__errors {
    color: var(--color-support-error);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    margin-block-end: var(--spacing-m);
  }

  &__upload {
    border: 1px dashed var(--color-border-dark);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
    margin-block-end: var(--spacing-m);
    margin-block-start: var(--spacing-m);
    padding-block-end: var(--spacing-m);
    padding-block-start: var(--spacing-m);
    text-align: center;

    &--active {
      opacity: 0.5;
    }

    button {
      margin: auto;
    }

    & + #{$parent}__error {
      margin-block-end: var(--spacing-s);
    }
  }

  &__file {
    align-items: center;
    display: flex;
    gap: var(--spacing-xs);
    margin-block-end: var(--spacing-m);
    margin-block-start: var(--spacing-xs);
  }

  :global(.form-group) {
    display: grid;
    column-gap: var(--spacing-m);
    grid-template-columns: 1fr 1fr;
  }

  :global(.form-group__field) {
    &:not(#{$parent}__halfFieldWidth) {
      grid-column: 1 / -1;
    }

    &:not(:last-child) {
      margin-block-end: 0;
    }

    &:not(:last-of-type) {
      margin-block-end: var(--spacing-m);
    }

    #{$parent}__error {
      margin-block-start: var(--spacing-xs);
    }

    #{$parent}__maxLength {
      margin-block-start: var(--spacing-2xs);
      position: absolute;
      right: 0;
      top: 100%;
    }
  }

  &__checkbox + :global(.form-group__field) {
    margin-block-start: var(--spacing-m);
  }

  :global(.spanAll) {
    grid-column: 1 / -1;
  }

  :global(.g-recaptcha) {
    margin-block-end: var(--spacing-s);
    margin-block-start: var(--spacing-m);
  }

  &__error {
    color: var(--color-support-error);
  }

  &__captcha {
    margin-block-end: var(--spacing-xs);
  }

  &__interests {
    display: grid;
    column-gap: var(--spacing-m);
    grid-template-columns: 1fr 1fr;
  }

  &__marketing {
    display: flex;
    flex-direction: column;
    margin-block-start: var(--spacing-m);
  }

  &__checkbox {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    padding-block-end: var(--spacing-xs);
    padding-block-start: var(--spacing-s);

    #{$parent}__error {
      flex-basis: 100%;
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    margin-block-start: var(--spacing-m);
    white-space: nowrap;

    &#{&} button {
      padding-block-end: 0;
      padding-block-start: 0;
    }

    &#{&} button + button {
      margin-inline-start: auto;
    }
  }

  &__notMember {
    display: flex;
    gap: var(--spacing-xs);
  }

  &__goToWellaStore {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    white-space: normal;

    a {
      white-space: nowrap;
    }
  }

  &__image {
    @include aspect-ratio($force: true);
    // 1 / 1 aspect ratio
    --ratio-percent: 100%;
  }

  @include mq($from: l) {
    &__footer {
      flex-wrap: nowrap;
    }
    &__footer__DE {
      flex-wrap: wrap;
    }
  }
}
