@import '@we-make-websites/styles-lib/src/config/configuration';
.signUpPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  &__preferredLanguage {
    margin-bottom: 20px;
  }
  &__preferredLanguageLabel {
    &::after {
      color: #606060;
    }
  }

  &__formContainer {
    display: flex;

    & h2 {
      @include text-heading-h2;
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        @include text-heading-h4;
        font-weight: var(--font-weight-medium);
        margin-bottom: 8px;
      }
    }

    & p {
      font-size: 14px;
      line-height: 22.4px;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  &__radioContainer {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-m);
    padding: 20px 0;

    :global {
      .radio__label {
        @media screen and (max-width: 767px) {
          color: var(--color-text-dark);
          line-height: 22px;
          font-size: 14px;
          font-weight: var(--font-weight-regular);
        }
      }
    }
  }

  &__lastNameField {
    margin-block-end: var(--spacing-l);
  }

  &__namesContainer {
    margin-block-end: var(--spacing-l);
    display: flex;
    margin-block-end: 0px;
    gap: var(--spacing-m);
    & > div {
      width: 50%;
    }
    @media screen and (min-width: 768px) {
      & > div {
        flex: 1;
      }
    }
  }

  &__submit {
    width: 100% !important;
    @media screen and (max-width: 767px) {
      font-size: 12px !important;
      line-height: 18px !important;
      height: 45px;
    }

    &__disabled {
      cursor: not-allowed !important;
      background-color: var(--color-button-disabled);
    }
  }

  &__radioDisclaimer {
    color: var(--color-border-input-field);
    line-height: 12px;
    font-size: 12px;
    font-weight: 300;
    margin: 20px 0px 0px 0px;

    @media screen and (max-width: 767px) {
      color: var(--color-text-dark);
      line-height: 22px;
      font-size: 14px;
      font-weight: var(--font-weight-regular);
      margin-top: 16px;
    }
  }

  &__birthInputDisclaimer {
    color: var(--color-border-input-field);
    line-height: 12px;
    font-size: 12px;
    font-weight: 300;
    margin: -4px 0px 5px 0px;
  }

  &__unsubribeDisclaimer {
    line-height: 16px;
    font-size: 12px;
    font-weight: 300;
    margin-top: 24px;
    @media screen and (max-width: 767px) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  &__closeButton {
    all: unset;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;

    &:hover {
      cursor: pointer;
    }
  }

  & form {
    width: 50%;
    padding: 40px;
    & > div {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 20px;
      width: 100%;
      margin-top: 12px;
    }
    @media screen and (max-width: 768px) {
      padding-top: 0px;
      padding: 20px;
    }
  }

  &__imageContainer {
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 767px) {
      position: static;
      width: 100%;
      height: 120px;
      background-position: 50% calc(50% + 20px);
    }
  }

  &__error {
    color: var(--color-support-error);
    font-size: 12px;
    font-weight: 300;
    margin-top: 10px;
  }

  &__dateOfBirthInput {
    margin-bottom: 20px;
  }

  &__birthInputDisclaimer {
    &__desktop {
      display: none;
      @media screen and (min-width: 768px) {
        display: block;
      }
    }
    &__mobile {
      display: block;
      color: var(--color-text-dark);
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
}

.signUpConfirmedPopup {
  min-height: 320px;

  & > div:first-child {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 16px;

    & > h2 {
      width: 100%;
      @include text-heading-h2;
      text-align: left;
    }
    & > p {
      width: 100%;
      font-size: var(--font-size-xl);
      line-height: var(--line-height-h6);
      text-align: left;
    }
  }

  @media screen and (max-width: 768px) {
    height: 320px;
    display: flex;
    height: fit-content;
    flex-direction: column-reverse;

    & > div:first-child {
      width: 100%;
      height: fit-content;

      & > h2 {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }
  @media screen and (min-width: 769px) {
    & > div:first-child {
      padding: 40px;
    }
  }

  &__imageContainer {
    position: absolute;
    left: 50%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 768px) {
      position: static;
      height: 343px;
      width: 100%;
      background-position: 50% 50%;
    }
  }
  &__shopNowButton {
    width: 100%;
    margin-top: auto;
    height: 45px;

    @media screen and (max-width: 768px) {
      padding: 20px 5px;
    }
  }

  &__closeButton {
    all: unset;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;

    &:hover {
      cursor: pointer;
    }
  }
}
