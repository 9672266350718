@import '@we-make-websites/styles-lib/src/config/configuration';
.breadcrumbs {
  $parent: &;

  background-color: var(--color-white);
  padding-block-end: calc(var(--spacing-m) + var(--spacing-3xs));
  padding-block-start: calc(var(--spacing-m) + var(--spacing-3xs));

  &__home {
    color: var(--color-mid-grey);
  }

  &__link:not(#{$parent}__home) {
    margin-inline-start: var(--spacing-2);

    &::before {
      content: '/';
      margin-inline-end: var(--spacing-s);
      margin-inline-start: var(--spacing-s);
      color: var(--color-mid-grey);
    }
  }

  &__link:not(#{$parent}__current) {
    margin-inline-start: var(--spacing-2);
  }

  &__link:not(#{$parent}__current, #{$parent}__home) {
    color: var(--color-mid-grey);
  }
}
