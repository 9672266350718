@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Google re-captcha Component.
 * -----------------------------------------------------------------------------
 * Base styles for Google re-captcha Component.
 *
 */
.captcha {
  &__wrapper {
    margin-block-end: var(--spacing-m);
    margin-block-start: var(--spacing-m);
  }

  & + &__error {
    color: var(--color-support-error);
    margin-block-start: var(--spacing-s);
  }
}
